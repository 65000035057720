import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import MissionsView from '@/views/MissionsView.vue'
import SocialView from '@/views/SocialView.vue'
import UpgradesView from '@/views/UpgradesView.vue';
import RewardsView from '@/views/RewardsView.vue';

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/missions',
    name: 'missions',
    component: MissionsView,
  },
  {
    path: '/stats',
    name: 'stats',
    component: SocialView,
  }, 
  {
    path: '/upgrades',
    name: 'upgrades',
    component: UpgradesView,
  },
  {
    path: '/rewards',
    name: 'rewards',
    component: RewardsView,
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
